import * as THREE from 'three';
//import VideoConvertido from './VideoConvertido.mp4';

class Video360 {
  scene;
  camera;
  renderer;
  geometry;
  videoElement;
  material;
  mesh;
  mouseDown;

  constructor(width, height) {
    // console.log("si estoy importando la clase de 360");
    this.width = width;
    this.height = height;
    this.mouseDown = false;

    this.scene = new THREE.Scene();
    // this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 1, 100);
    this.camera = new THREE.PerspectiveCamera(50, this.width / this.height, 1, 100);

    this.definoParametros();

    this.renderer.setAnimationLoop(() => this.renderer.render(this.scene, this.camera));

    this.renderer.domElement.addEventListener('wheel', e => {
      // console.log("entro a wheel");
      this.camera.fov = this.clamp(this.camera.fov + e.deltaY / 10, 10, 120);
      // need to call this function after changing most of properties in PerspectiveCamera
      this.camera.updateProjectionMatrix();
    });

    this.renderer.domElement.addEventListener('mousedown', e => {
      // console.log("entre a mousedown", this.mouseDown);
      if (e.button === 0) this.mouseDown = true;
      // console.log("final mousedown", this.mouseDown);
    });

    window.addEventListener('mouseup', e => {
      // console.log('mouseDown', this.mouseDown);
      if (e.button === 0) this.mouseDown = false;
    });

    window.addEventListener('mousemove', e => {
      if (!this.mouseDown) return;

      const { movementX, movementY } = e;

      // rotateX: rotate vertically since x-axis is horizontal
      const rotateX = movementY / 500;
      // const rotateX = movementY / 100;
      // const rotateY = movementX / 100;
      const rotateY = movementX / 200;

      this.camera.rotateX(rotateX);
      this.camera.rotateY(rotateY);
    });
  }

  clamp = (v, min, max) => Math.max(min, Math.min(v, max));

  async definoParametros() {
    try {
      this.renderer = new THREE.WebGLRenderer();
      setTimeout(() => {
        let elemento = document.getElementById('divRenderer');
        elemento.innerHTML = '';
      }, 1000); // Change this delay as needed

      await this.rendererMetodo();
      await this.geometryMetodo();
      await this.videoTagMetodo();
      await this.textureMetodo();
      await this.materialMetodo();
      await this.meshMetodo();
    } catch (error) {
      console.log('Inicio todo - error');
      console.log(error);
    }
  }

  rendererMetodo() {
    try {
      this.renderer.setSize(this.width, this.height);

      setTimeout(() => {
        let elemento = document.getElementById('divRenderer');
        // elemento.innerHTML = '';
        elemento.appendChild(this.renderer.domElement);
      }, 1000); // Change this delay as needed
    } catch (error) {
      console.log('error rendererMetodo');
      console.log(error);
    }
  }

  geometryMetodo() {
    try {
      this.geometry = new THREE.SphereGeometry(15, 32, 16);
    } catch (error) {
      console.log('error geometryMetodo');
      console.log(error);
    }
  }

  videoTagMetodo() {
    try {
      this.videoElement = document.createElement('video');
      this.videoElement.src = 'https://s.bepro11.com/vr-video-sample.mp4';
      // (this.videoElement).src = VideoConvertido;
      this.videoElement.loop = true;
      this.videoElement.muted = true;
      this.videoElement.playsInline = true;
      this.videoElement.crossOrigin = 'anonymous';
      this.videoElement.play();
    } catch (error) {
      console.log('error videoTagMetodo');
      console.log(error);
    }
  }

  textureMetodo() {
    try {
      this.texture = new THREE.VideoTexture(this.videoElement);
    } catch (error) {
      console.log('error textureMetodo');
      console.log(error);
    }
  }

  materialMetodo() {
    try {
      this.material = new THREE.MeshBasicMaterial({ map: this.texture });
      // need to use back side - surface of the sphere is facing outside but we put the camera inside of the sphere
      this.material.side = THREE.BackSide;
    } catch (error) {
      console.log('error materialMetodo');
      console.log(error);
    }
  }

  meshMetodo() {
    try {
      this.mesh = new THREE.Mesh(this.geometry, this.material);
      this.scene.add(this.mesh);
    } catch (error) {
      console.log('error meshMetodo');
      console.log(error);
    }
  }
}

export default Video360;
