import React from 'react';
import Video360 from './Visor360';

function Reproductor360() {
  let instancia = new Video360(640, 480);
  return (
    <div>
      <h1>
        <code>Hola a través de un H1</code>
      </h1>
      <div style={{ width: '100vw', backgroundColor: 'red', height: '40vh', display: 'inline-flex' }}>
        <div style={{ width: '33vw', backgroundColor: 'blue', height: '40vh' }}></div>
        <div style={{ width: '33vw', backgroundColor: 'white', height: '40vh' }}>
          <div id="divRenderer">{/* AQUI DEBE IR TODO EL CONTENIDO DE LA CLASE VISOR360 */}</div>
        </div>
        <div style={{ width: '33vw', backgroundColor: 'blue', height: '40vh' }}></div>
      </div>
    </div>
  );
}

export default Reproductor360;
